/**
 * @license
 * Copyright 2023 payoh.net
 */
////////////
import { LitElement, html, css, PropertyValues } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
//////////
@customElement('payan-btn')
export class PayanBtn extends LitElement {
  static override styles = css`
    :host {
          font-family: system-ui;
    }
    .disable{
     pointer-events: none;
     opacity: 0.7;
    }
    .btn {
        background: #4a39ff;
        color: white;
        border: none;
        padding: 10px 30px;
        font-size: 15px;
        font-weight: 600;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        max-width: 300px;
    }
    .btn:hover{
      background:  #695cfb
    }
  `;

  @property({ type: Array })
  purchase_units = [];

  @property()
  customId = 'xxxxxxx';

  @property()
  publicKey = '';

  @property()
  amount = 0;

  @property()
  styles = '';

  @property()
  displayType: 'iframe' | 'redirect' = 'iframe';

  @property()
  callBackUrl = '';

  @property({ type: Function })
  onInit = new Function();

  @property({ type: Function })
  onApprove = new Function();

  @property({ type: Function })
  onCancel = new Function();

  @property({ type: Function })
  onError = new Function();

  @property({ type: Function })
  onClick = new Function();

  // @property({ type: Function })
  // onChangeProps = new Function();

  @state()
  loading = false;

  cors = ["https://payoh.net", "http://localhost:3000"]

  constructor() {
    super();
  }

  override render() {
    const t = this.title ? this.title : 'Thanh toán';
    return html`
      <button @click=${this.onClick} style="${this.styles}" disable="${this.loading}"
      class="btn ${this.loading ? 'disable' : ''}" part="button">
         ${!this.loading ? t : 'Đang thanh toán...'}
      </button>
      <slot></slot>
    `;
  }

  protected override firstUpdated(_changedProperties: PropertyValues): void {
    this.updateComplete.then(() => {
      this.onInit();

    })
    console.log('purchase_units:', this.purchase_units)
    console.log('customId:', this.customId)

    window.addEventListener("message", (event: any) => {
      // IMPORTANT: check the origin of the data!
      if (
        this.cors.includes(event.origin) &&
        typeof event?.data == "object" &&
        event?.data?.code == 200 &&
        event?.data?.site == "payoh.net"
      ) {
        this.onApprove(event?.data);
        this.hideBackdrop();
      }

      if (
        this.cors.includes(event.origin) &&
        typeof event?.data == "object" &&
        event?.data?.code == 201 &&
        event?.data?.site == "payoh.net"
      ) {
        this.onCancel(event?.data);
        this.hideBackdrop();
      }
    });
  }

  // protected override updated(_changedProperties: PropertyValues): void {
  //   this.onChangeProps(_changedProperties);
  // }


  async createPayment() {
    const newTab = window.document.createElement("a");
    newTab.target = "_blank";

    if (this.displayType == 'redirect') {
      if (!this.callBackUrl || this.callBackUrl?.length < 10) {
        return this.onError({ code: 998, message: 'Thông số "callBackUrl" là bắt buộc khi kiểu hiển thị là "redirect"' });
      } else {
        window.open();
      }
    }

    this.loading = true;

    try {

      const model = {
        customId: this.customId,
        products: this.purchase_units,
        amount: +this.amount,
        callbackUrl: this.callBackUrl
      };
      if (!this.callBackUrl || this.displayType != 'redirect') {
        delete (model as any).callbackUrl;
      }

      const res = await fetch(`https://apis.payoh.net/merchant/fiatPayment/integration/${this.publicKey}/depositRequest`, {
        method: 'POST',
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(model)
      }).then((response) => response.json());

      if (res.status == 200) {
        let url = res.data.paymentUrl;
        if (this.displayType == "iframe") {
          url = url + `&site=${window.location.origin}`;
        }

        if (this.displayType == 'redirect') {
          newTab.href = url;
          setTimeout(() => {
            newTab.click();
          }, 1);
        } else {

          this.showBackdrop(url);
        }
      } else {
        this.onError(res);
      }

      this.loading = false
    } catch (error: any) {
      this.onError({ code: 999, message: 'Lỗi không xác định. Vui lòng liên hệ với bộ phận kỹ thuật để được trợ giúp | ' + error.message });
      this.loading = false
    }

  }

  hideBackdrop() {
    const f = document.getElementById("payan-ifr");
    const b = document.getElementById("payan-b");

    if (b) {
      document.body.removeChild(b);
    }
    if (f) {
      document.body.removeChild(f);
    }
  }

  showBackdrop(url: string) {
    const a = document.createElement('a');
    const b = document.createElement('b');
    a.innerText = 'Nhấn để hủy';
    a.style.display = 'block';
    a.style.color = 'white';
    a.style.fontWeight = '600';
    a.style.textDecoration = 'underline';
    a.style.cursor = 'pointer';

    b.innerText = '×';
    b.style.color = 'black';
    b.style.fontWeight = '600';
    b.style.fontSize = '32px';
    b.style.cursor = 'pointer';
    b.style.zIndex = '199999';
    b.style.position = 'fixed';
    b.style.top = '5px';
    b.style.right = '20px';
    b.id = 'payan-b';
    b.addEventListener('click', () => this.hideBackdrop());

    a.addEventListener('click', () => this.hideBackdrop());

    const ifr = document.createElement('iframe');
    ifr.src = url;
    ifr.style.width = '100%';
    ifr.style.height = '100%';
    ifr.style.background = 'white';
    ifr.style.border = 'none';
    ifr.id = 'payan-ifr';
    ifr.style.position = 'absolute';
    ifr.style.top = '0';
    ifr.style.left = '0';
    ifr.style.zIndex = '99999';
    document.body.appendChild(ifr);
    document.body.appendChild(b);

  }

}

declare global {
  interface HTMLElementTagNameMap {
    'payan-btn': PayanBtn;
  }
}
